import revive_payload_client_G9PLOc3NeQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xFGWRY4tfT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RSVaBD6rgy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RtWvqKmX77 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_hu2boxlt5vyjitdsggqrfrrrri/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import sentry_client_Ub7jFNgAqV from "/opt/buildhome/repo/modules/sentry/runtime/sentry.client.ts";
import payload_client_MjLa7ZcFHT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2t7N1JJ11n from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_A0EwSMCSgy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_iuDrASrpaC from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_MXs7679g94 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_nSw9YyR9uB from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_s_obyhxwh2kh4lky7shfonfra2em/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_KZknhVSVpi from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_s_obyhxwh2kh4lky7shfonfra2em/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_R5IIkpC6Me from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_s_obyhxwh2kh4lky7shfonfra2em/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import umami_iN1DWXMIUx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-umami@2.6.7/node_modules/nuxt-umami/plugins/umami.ts";
import marker_io_client_3vX6FuptAi from "/opt/buildhome/repo/plugins/marker-io.client.ts";
import defaults_wIPpwri7fJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_s_obyhxwh2kh4lky7shfonfra2em/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_G9PLOc3NeQ,
  unhead_xFGWRY4tfT,
  router_RSVaBD6rgy,
  _0_siteConfig_RtWvqKmX77,
  sentry_client_Ub7jFNgAqV,
  payload_client_MjLa7ZcFHT,
  navigation_repaint_client_2t7N1JJ11n,
  check_outdated_build_client_A0EwSMCSgy,
  chunk_reload_client_iuDrASrpaC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MXs7679g94,
  siteConfig_nSw9YyR9uB,
  inferSeoMetaPlugin_KZknhVSVpi,
  titles_R5IIkpC6Me,
  umami_iN1DWXMIUx,
  marker_io_client_3vX6FuptAi,
  defaults_wIPpwri7fJ
]