
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as datenschutzCG467UHJZpMeta } from "/opt/buildhome/repo/pages/(legal)/datenschutz.vue?macro=true";
import { default as impressumQsNYIaYXaPMeta } from "/opt/buildhome/repo/pages/(legal)/impressum.vue?macro=true";
import { default as _91vcard_93dmZdjgBojRMeta } from "/opt/buildhome/repo/pages/[vcard].vue?macro=true";
import { default as agenturiBNJX2somIMeta } from "/opt/buildhome/repo/pages/agentur.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as kontaktZtJTLRoa1vMeta } from "/opt/buildhome/repo/pages/kontakt.vue?macro=true";
import { default as barber_45toolsif6fPYIMOKMeta } from "/opt/buildhome/repo/pages/projekt/barber-tools.vue?macro=true";
import { default as echt_45jetztj5LugbRSUuMeta } from "/opt/buildhome/repo/pages/projekt/echt-jetzt.vue?macro=true";
import { default as falcondevIqdHzEFqOuMeta } from "/opt/buildhome/repo/pages/projekt/falcondev.vue?macro=true";
import { default as gruender_45raketemvi3p7AjvNMeta } from "/opt/buildhome/repo/pages/projekt/gruender-rakete.vue?macro=true";
import { default as hybrid_45tourkNPqvojONxMeta } from "/opt/buildhome/repo/pages/projekt/hybrid-tour.vue?macro=true";
import { default as klassik_45deluxeU593v4S9D8Meta } from "/opt/buildhome/repo/pages/projekt/klassik-deluxe.vue?macro=true";
import { default as leaderforumOy5PZpToElMeta } from "/opt/buildhome/repo/pages/projekt/leaderforum.vue?macro=true";
import { default as legaleosfnKTrLgD4dMeta } from "/opt/buildhome/repo/pages/projekt/legaleos.vue?macro=true";
import { default as manula_45eisoak19jTURsMeta } from "/opt/buildhome/repo/pages/projekt/manula-eis.vue?macro=true";
import { default as medical_45illustrationYAW1gxCBD5Meta } from "/opt/buildhome/repo/pages/projekt/medical-illustration.vue?macro=true";
import { default as microsoft_45kleineventsMezT8dxLb3Meta } from "/opt/buildhome/repo/pages/projekt/microsoft-kleinevents.vue?macro=true";
import { default as microsoft_45mbs9HEjpIU0s3Meta } from "/opt/buildhome/repo/pages/projekt/microsoft-mbs.vue?macro=true";
import { default as monster_45familyh25RtZ89l8Meta } from "/opt/buildhome/repo/pages/projekt/monster-family.vue?macro=true";
import { default as oc_45hauFzGrEOqV1Meta } from "/opt/buildhome/repo/pages/projekt/oc-ha.vue?macro=true";
import { default as startup_45campmshh3xgoKzMeta } from "/opt/buildhome/repo/pages/projekt/startup-camp.vue?macro=true";
import { default as projekteaizBHf7VjrMeta } from "/opt/buildhome/repo/pages/projekte.vue?macro=true";
import { default as component_45stubeIihz0VKsqMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubeIihz0VKsq } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_h4eaemqokl3kggkforb6ynpuly/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "datenschutz",
    path: "/datenschutz/",
    component: () => import("/opt/buildhome/repo/pages/(legal)/datenschutz.vue")
  },
  {
    name: "impressum",
    path: "/impressum/",
    component: () => import("/opt/buildhome/repo/pages/(legal)/impressum.vue")
  },
  {
    name: "vcard",
    path: "/:vcard()/",
    meta: _91vcard_93dmZdjgBojRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[vcard].vue")
  },
  {
    name: "agentur",
    path: "/agentur/",
    component: () => import("/opt/buildhome/repo/pages/agentur.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "kontakt",
    path: "/kontakt/",
    component: () => import("/opt/buildhome/repo/pages/kontakt.vue")
  },
  {
    name: "projekt-barber-tools",
    path: "/projekt/barber-tools/",
    component: () => import("/opt/buildhome/repo/pages/projekt/barber-tools.vue")
  },
  {
    name: "projekt-echt-jetzt",
    path: "/projekt/echt-jetzt/",
    component: () => import("/opt/buildhome/repo/pages/projekt/echt-jetzt.vue")
  },
  {
    name: "projekt-falcondev",
    path: "/projekt/falcondev/",
    component: () => import("/opt/buildhome/repo/pages/projekt/falcondev.vue")
  },
  {
    name: "projekt-gruender-rakete",
    path: "/projekt/gruender-rakete/",
    component: () => import("/opt/buildhome/repo/pages/projekt/gruender-rakete.vue")
  },
  {
    name: "projekt-hybrid-tour",
    path: "/projekt/hybrid-tour/",
    component: () => import("/opt/buildhome/repo/pages/projekt/hybrid-tour.vue")
  },
  {
    name: "projekt-klassik-deluxe",
    path: "/projekt/klassik-deluxe/",
    component: () => import("/opt/buildhome/repo/pages/projekt/klassik-deluxe.vue")
  },
  {
    name: "projekt-leaderforum",
    path: "/projekt/leaderforum/",
    component: () => import("/opt/buildhome/repo/pages/projekt/leaderforum.vue")
  },
  {
    name: "projekt-legaleos",
    path: "/projekt/legaleos/",
    component: () => import("/opt/buildhome/repo/pages/projekt/legaleos.vue")
  },
  {
    name: "projekt-manula-eis",
    path: "/projekt/manula-eis/",
    component: () => import("/opt/buildhome/repo/pages/projekt/manula-eis.vue")
  },
  {
    name: "projekt-medical-illustration",
    path: "/projekt/medical-illustration/",
    component: () => import("/opt/buildhome/repo/pages/projekt/medical-illustration.vue")
  },
  {
    name: "projekt-microsoft-kleinevents",
    path: "/projekt/microsoft-kleinevents/",
    component: () => import("/opt/buildhome/repo/pages/projekt/microsoft-kleinevents.vue")
  },
  {
    name: "projekt-microsoft-mbs",
    path: "/projekt/microsoft-mbs/",
    component: () => import("/opt/buildhome/repo/pages/projekt/microsoft-mbs.vue")
  },
  {
    name: "projekt-monster-family",
    path: "/projekt/monster-family/",
    component: () => import("/opt/buildhome/repo/pages/projekt/monster-family.vue")
  },
  {
    name: "projekt-oc-ha",
    path: "/projekt/oc-ha/",
    component: () => import("/opt/buildhome/repo/pages/projekt/oc-ha.vue")
  },
  {
    name: "projekt-startup-camp",
    path: "/projekt/startup-camp/",
    component: () => import("/opt/buildhome/repo/pages/projekt/startup-camp.vue")
  },
  {
    name: "projekte",
    path: "/projekte/",
    component: () => import("/opt/buildhome/repo/pages/projekte.vue")
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/leistungen",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/leistungen/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/projekte/design",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/projekte/design/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/projekte/events",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/projekte/events/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/man-trucknology",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/man-trucknology/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/eeb-publikation",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/eeb-publikation/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/stanford-guitars",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/stanford-guitars/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/portfolio-customer-success-unit",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/portfolio-customer-success-unit/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/maybach-guitars",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/maybach-guitars/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/radiopark",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/radiopark/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/portfolio-microsoft-broschure",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/portfolio-microsoft-broschure/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/portfolio-bosch-siemens",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/portfolio-bosch-siemens/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/leaderforum",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/leaderforum/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/portfolio-microsoft-powerapp-your-teams",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/portfolio-microsoft-powerapp-your-teams/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/ulassa-animation",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/ulassa-animation/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/microsoft-dpk",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/project/microsoft-dpk/",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/dmenzel",
    component: component_45stubeIihz0VKsq
  },
  {
    name: component_45stubeIihz0VKsqMeta?.name,
    path: "/dmenzel/",
    component: component_45stubeIihz0VKsq
  }
]